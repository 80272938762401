import {
  useFormContext,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import {
  TextField as BaseTextField,
  TextFieldProps as BaseTextFieldProps,
  Stack,
} from '@mui/material'

export interface CounterProps {
  showCounter: boolean
  maxLength: number
}

export type TextFieldProps = Partial<BaseTextFieldProps> & {
  name: UseControllerProps['name']
  counterProps?: CounterProps
  defaultValue?: string
  hintText?: string
  subtitle?: string
}

const TextField = ({
  name,
  label,
  defaultValue = '',
  hintText = undefined,
  subtitle = undefined,
  counterProps,
  ...props
}: TextFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue })

  let helperText = counterProps
    ? error
      ? error.message
      : typeof inputProps.value === 'string'
        ? `${inputProps.value.length}/${counterProps.maxLength}`
        : undefined
    : error?.message

  if (helperText === undefined && hintText)
    helperText = hintText

  const composeHelper = () => {
    if (helperText && subtitle) return <Stack>
      <span>{helperText}</span>
      <span>{subtitle}</span>
    </Stack>
    if (helperText) return helperText
    if (subtitle) return subtitle
    return undefined
  }

  return (
    <BaseTextField
      label={label}
      inputRef={ref}
      error={!!error}
      helperText={composeHelper()}
      {...inputProps}
      {...props}
    />
  )
}

export default TextField
