export const appendTextToFilename = (
  fileLink: string,
  textToAppend: string
): string => {
  // Check if the file link contains an extension
  const lastDotIndex = fileLink.lastIndexOf('.')

  if (lastDotIndex === -1) {
    throw new Error('File does not have an extension.')
  }

  // Split the file link into the name part and the extension part
  const fileName = fileLink.slice(0, lastDotIndex)
  const fileExtension = fileLink.slice(lastDotIndex)

  // Append the specified text to the file name
  const newFileName = `${fileName}${textToAppend}${fileExtension}`

  return newFileName
}
